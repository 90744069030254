import { useEffect } from 'react';
import { navigate } from 'gatsby';

const Page404 = () => {
	useEffect(() => {
		navigate('/en/page-404');
	}, []);

	return null;
};

export default Page404;
